import React from "react";
import { useSearchParams } from "react-router-dom";
import surveyJson from "./survey.json";
import surveyTheme from "./survey_theme";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";
import { autocomplete } from "surveyjs-widgets";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
//const stripe = require('stripe')('sk_test_51OcVuWE7IkQ9hURDUrYKxYBNOliqQQYOMEms6oLcL6DkvovbeF7Q87S6Zfoq2p0qNoYLlwhVQoB3MLfxeEz5nuTj00WpZgssKG')
const stripe = require('stripe')('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');


window["$"] = window["jQuery"] = $;
require("easy-autocomplete/dist/jquery.easy-autocomplete.js");
autocomplete(SurveyCore);

function submitSurveyData(data) {

  const ENDPOINT_URL = "https://aidiagme.fr/runSurveyPaiment";
  const UPDATE_URL = "https://aidiagme.fr/runSurveyAddPostres/UPDATE";

  fetch(UPDATE_URL, {
    method: "POST" ,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })

  fetch(ENDPOINT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => console.error(error));
}

 function App() {
  const [searchParams] = useSearchParams();
  const survey = new Model(surveyJson);
  const sessionId = searchParams.get("session_id");

  console.log('sessionid=',sessionId);
  
  let client_reference_id = "";
  let prompt = "";
  let lang = "";
  let header = "";
  let footer = "";
  let passwordFile = "";
  let email = "";
  let nom = "";
  let prenom = "";

  stripe.checkout.sessions.retrieve(sessionId).then((data) => {
    console.log('Data:', data.client_reference_id);
    client_reference_id =data.client_reference_id+".pdf";

    let urlDbSurveyInfo = "https://aidiagme.fr/getSurvey?Id="+client_reference_id;

    console.log('urlDbSurveyInfo=',urlDbSurveyInfo);
    
      fetch(urlDbSurveyInfo, {
        method: "GET"
    }).then((response) => response.json())
      .then((data) => {
        console.log('data=');
        console.log(data);
        //console.log(data[0].filename);
        try {
          if ( data[0].prompt!=null)
          prompt =  data[0].prompt;   
        } catch (error) {
          console.error("Erreur lors du prompt:", error);
          //logger.error("Erreur lors du prompt:", error);
        }

        try {
          if ( data[0].lang!=null)
          lang =  data[0].lang;   
        } catch (error) {
          console.error("Erreur lors du lang:", error);
        }

        try {
          if ( data[0].header!=null)
          header =  data[0].header;   
        } catch (error) {
          console.error("Erreur lors du header:", error);
        }

        try {
          if ( data[0].footer!=null)
          footer =  data[0].footer;   
        } catch (error) {
          console.error("Erreur lors du footer:", error);
        }

        try { 
          if ( data[0].filepassword!=null)
          passwordFile =  data[0].filepassword;   
        } catch (error) {
          console.error("Erreur lors du passwordFile:", error);
        }

        try {
          if ( data[0].email!=null)
          email =  data[0].email;   
        } catch (error) {
          console.error("Erreur lors du email:", error);
        }

        try {
          if ( data[0].lastname!=null)
          nom =  data[0].lastname;   
        } catch (error) {
          console.error("Erreur lors du nom:", error);
        }

        try {
          if ( data[0].firstname!=null)
          prenom =  data[0].firstname;   
        } catch (error) {
          console.error("Erreur lors du prenom:", error);
        }
    
        // Appliquer le thème CSS importé
        survey.applyTheme(surveyTheme);

        console.log('prompt=',prompt);
        console.log('lang=',lang);
        console.log('header=',header);
        console.log('footer=',footer);
        console.log('passwordFile=',passwordFile);
        console.log('email=',email);

        if(lang === "") lang = "fr";
        survey.locale = lang;
  });
      }).catch((error) => console.error(error));


  var converter = new Converter();
  survey.onTextMarkdown.add(function (survey, options) {
    var str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  });
  
  survey.onComplete.add((survey) => {
    survey.setValue("email", email);
    survey.setValue("passwordFile", passwordFile);
    survey.setValue("prompt", prompt);
    survey.setValue("lang", lang);
    survey.setValue("header", header);
    survey.setValue("footer", footer);
    survey.setValue("nameandfirstname", nom + ' ' + prenom);
    let survey_id = client_reference_id.replace(".pdf","");
    survey.setValue("survey_id", survey_id);
    survey.setValue("surveyId", client_reference_id);
    survey.setValue("session_id", sessionId);
    console.log(JSON.stringify(survey.data, null, 3));
    submitSurveyData(survey.data);
  });

  return <Survey model={survey} />;
}

export default App;
