import React, { useState } from 'react';

function submitSurveyPrompt(data) {
  const ENDPOINT_URL = "https://aidiagme.fr/runPromptSurvey";

  console.log(JSON.stringify(data));

  fetch(ENDPOINT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      alert("Survey submitted successfully!");
    })
    .catch((error) => {
      console.error("Error:", error);
      //alert("There was an error submitting your survey.");
    });
}

// const escapeSpecialChars = (input) => {
//   return input.replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
// };

function Prompt() {
  const [promptData, setPromptData] = useState("");
  const [promptName, setPromptName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (promptData.trim() !== "" && promptName.trim() !== "") {
      //const encodedPromptData =escapeSpecialChars(encodeURIComponent(promptData));
      submitSurveyPrompt({ prompt: promptData, name: promptName });
    } else {
      alert("Please enter both the prompt and a name before submitting.");
    }
  };

  return (
    <div>
      <h1>Survey File V1: </h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="prompt">Update Content file:</label>
          <textarea
            id="prompt"
            value={promptData}
            onChange={(e) => setPromptData(e.target.value)}
            rows="4"
            cols="50"
            placeholder="Enter your prompt here..."
          />
        </div>
        <div>
          <label htmlFor="promptName">Name File:</label>
          <input
            id="promptName"
            type="text"
            value={promptName}
            onChange={(e) => setPromptName(e.target.value)}
            placeholder="Enter a name for the prompt"
          />
        </div>     
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Prompt;
